<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}机构</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="机构名称" prop="name">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="机构代码" prop="organizationCode">
				<el-input v-model.trim="ruleForm.organizationCode" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="主管单位" prop="administrativeAuthorityDictionaryItemId">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.administrativeAuthorityDictionaryItemId"
					placeholder="请选择"
					clearable
					@change="changeSelect($event, 'administrativeAuthorityDictionaryItemId')"
				>
					<el-option v-for="(item, index) in authorityDictionaryItemList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item class="addressClass clearfix" label="地址">
				<el-form-item prop="provinceDictionaryItem" class="float-l addressClass-content">
					<el-select v-model="ruleForm.provinceDictionaryItem" placeholder="请选择省" disabled @change="changeEconomize($event, 1)">
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="cityDictionaryItem" class="float-l addressClass-content">
					<el-select v-model="ruleForm.cityDictionaryItem" placeholder="请选择市" disabled @change="changeEconomize($event, 2)">
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="regionCode" class="float-l addressClass-content mr0">
					<el-select v-model="ruleForm.regionCode" placeholder="请选择区县" disabled>
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
			</el-form-item> -->
			<el-form-item label="详细地址" class="attractionAddress" prop="address">
				<el-input
					v-model.trim="ruleForm.address"
					disabled
					placeholder="请选择详细地址"
					:style="{ width: isShowDetail ? '100%' : 'calc(100% - 74px)' }"
				></el-input>
				<el-button type="text" @click="chooseAddress" v-if="!isShowDetail">选择地址</el-button>
			</el-form-item>
			<el-form-item label="床位数量" prop="bedCount">
				<el-input v-model.trim="ruleForm.bedCount" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="机构等级" prop="levelDictionaryItemId" class="w50 mr16">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.levelDictionaryItemId"
					placeholder="请选择"
					clearable
					@change="changeSelect($event, 'levelDictionaryItemId')"
				>
					<el-option v-for="(item, index) in levelDictionaryItemList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="所有制形式" prop="ownershipDictionaryItemId" class="w50 mr0">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.ownershipDictionaryItemId"
					placeholder="请选择"
					clearable
					@change="changeSelect($event, 'ownershipDictionaryItemId')"
				>
					<el-option v-for="(item, index) in formsList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="机构性质" prop="characteristic">
				<el-input v-model.trim="ruleForm.characteristic" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr16" prop="principalName" label="负责人">
				<el-input v-model.trim="ruleForm.principalName" :disabled="isShowDetail" placeholder="姓名"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr0" prop="phoneNumber">
				<el-input v-model.trim="ruleForm.phoneNumber" :disabled="isShowDetail" maxlength="11" placeholder="联系电话"></el-input>
			</el-form-item>
			<el-form-item label="机构介绍" prop="description">
				<el-input type="textarea" :disabled="isShowDetail" v-model.trim="ruleForm.description" autosize></el-input>
			</el-form-item>
			<el-form-item label="上传图片" prop="images">
				<Upload
					ref="uploadImage"
					v-model="ruleForm.images"
					modulePath="DOCTOR_AND_NURSE"
					@uploadImg="uploadImg"
					operator="zhangl"
					:maxCount="6"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
		<choose-address ref="chooseAddress" @changeAddress="changeAddress" />
	</el-dialog>
</template>

<script>
import { verifyPhone, verifiyNumberInteger } from '@/utils/toolsValidate';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
		chooseAddress: () => import('./chooseAddress.vue'),
	},
	data() {
		var checkPhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入联系方式'));
			} else if (!verifyPhone(value)) {
				callback(new Error('请输入正确的联系方式'));
			} else {
				callback();
			}
		};
		var checkNumberInt = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入床位数'));
			} else if (!verifiyNumberInteger(value)) {
				callback(new Error('请输入正确的床位数'));
			} else {
				callback();
			}
		};
		return {
			createdVisible: false,
			ruleForm: {
				name: '',
				organizationCode: '',
				administrativeAuthorityDictionaryItemId: '',
				provinceDictionaryItem: '',
				cityDictionaryItem: '',
				regionCode: '',
				bedCount: 0,
				address: '',
				levelDictionaryItemId: null,
				ownershipDictionaryItemId: null,
				// institutionTypeDictionaryItemId: null,
				characteristic: '',
				principalName: '',
				phoneNumber: '',
				description: '',
				images: [],
				discriminationCode: 'H',
			},
			authorityDictionaryItemList: [], //主管单位list
			levelDictionaryItemList: [], //机构等级list
			formsList: [], //所有制形式list
			institutionTypeDictionaryItemList: [], //机构类别list

			rules: {
				name: [{ required: true, message: '请输入公园名称', trigger: 'blur' }],
				organizationCode: [{ required: true, message: '请输入', trigger: 'blur' }],
				bedCount: [{ required: true, validator: checkNumberInt, trigger: 'blur' }],
				characteristic: [{ required: true, message: '请输入', trigger: 'blur' }],
				administrativeAuthorityDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
				levelDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
				ownershipDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
				provinceDictionaryItem: [{ required: true, message: '请选择', trigger: 'change' }],
				cityDictionaryItem: [{ required: true, message: '请选择', trigger: 'change' }],
				regionCode: [{ required: true, message: '请选择', trigger: 'change' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
				address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
				principalName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
				phoneNumber: [{ required: true, validator: checkPhone, trigger: 'blur' }],
				images: [{ required: true, message: '请上传图片', trigger: 'change' }],
				// institutionTypeDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
			},
			attachmentInfo: [],
			saveLoading: false,
			link: '',
			economizeList: [], //省份
			marketList: [], //市
			districtList: [], //区县
		};
	},
	props: {
		isShowDetail: {
			type: Boolean,
			default: false,
		},
	},
	watch: {},
	mounted() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.getAttachment();
			this.getSponsorList();
			this.getInstitList();
			this.getFormsList();
			// this.getInstitTypeList();
			this.getEconomize();
			this.link = data.link;
			if (data.row) {
				this.$nextTick(() => {
					this.setRuleForm(data.row);
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			//新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取省份
		getEconomize() {
			this.marketList = [];
			this.districtList = [];
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 修改/查看
		setRuleForm(data) {
			this.ruleForm = JSON.parse(JSON.stringify(data));
			this.ruleForm.provinceDictionaryItem = data.regions && data.regions.length > 0 ? data.regions[0].code : null;
			this.ruleForm.cityDictionaryItem = data.regions && data.regions.length > 0 ? data.regions[1].code : null;
			this.ruleForm.regionCode = data.regions && data.regions.length > 0 ? data.regions[2].code : null;
			this.$set(this.ruleForm, 'administrativeAuthorityDictionaryItemId', data.administrativeAuthorityDictionaryItem?.id || null);
			this.$set(this.ruleForm, 'levelDictionaryItemId', data.levelDictionaryItem?.id || null);
			this.$set(this.ruleForm, 'ownershipDictionaryItemId', data.ownershipDictionaryItem?.id || null);
			this.ruleForm.images =
				(data.attachmentDtoMap &&
					data.attachmentDtoMap['DETAILPHOTO'].map((item) => {
						return {
							url: item.link,
							uid: item.id,
							title: item.title,
						};
					})) ||
				[];
		},
		// 获取市/区县
		changeEconomize(val, type) {
			// type 1市 2区县
			if (type === 1) {
				this.marketList = [];
				this.districtList = [];
				this.$http
					.get(this.api['ChinaRegions#index'].href, { params: { code: val, size: 9999, current: 1 } })
					.then((res) => {
						if (res.data && res.data.success) {
							this.marketList = res.data.collection || [];
						}
					})
					.catch((e) => {});
			} else {
				this.districtList = [];
				this.$http
					.get(this.api['ChinaRegions#index'].href, { params: { code: val, size: 9999, current: 1 } })
					.then((res) => {
						if (res.data && res.data.success) {
							this.districtList = res.data.collection || [];
						}
					})
					.catch((e) => {});
			}
		},
		// 获取主管单位list
		getSponsorList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'COMPETENT_ORGANIZATION' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.authorityDictionaryItemList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取机构等级list
		getInstitList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'INSTITUTIONAL_LEVEL' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.levelDictionaryItemList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取所有制list
		getFormsList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'FORMS_OF_OWNERSHIP' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.formsList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取机构类别
		// getInstitTypeList() {
		// 	this.$http
		// 		.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'INSTITUTIONAL_TYPE' } })
		// 		.then((res) => {
		// 			if (res.data && res.data.success) {
		// 				this.institutionTypeDictionaryItemList = res.data.collection || [];
		// 			}
		// 		})
		// 		.catch((e) => {});
		// },
		// 获取附件组
		getAttachment() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'MEDICAL_INSTITUTION' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		changeSelect(val, name) {
			this.ruleForm[name] = val;
		},
		// 选择地址
		chooseAddress() {
			this.$refs.chooseAddress.chooseAddressVisible = true;
		},
		// 获取到的地址
		changeAddress(data) {
			const { addressData } = data;
			this.ruleForm.address =
				addressData.province + addressData.city + addressData.district + addressData.township + addressData.street + addressData.streetNumber;
			this.ruleForm.longitude = data.lng;
			this.ruleForm.latitude = data.lat;
			this.ruleForm.provinceDictionaryItem = addressData.adcode.slice(0, 2);
			this.ruleForm.cityDictionaryItem = addressData.adcode.slice(0, 4);
			// this.ruleForm.regionCode = addressData.adcode;
			this.getTownCode({ code: addressData.adcode, title: addressData.township });
		},
		// 查询乡镇/街道code
		getTownCode({ code, title }) {
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code, title } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.ruleForm.regionCode = res.data.collection[0].code;
					}
				})
				.catch((e) => {});
		},
		// 保存
		async onSave(formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						discriminationCode: 'O',
						attachments: this.ruleForm.images.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'DETAILPHOTO').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'DETAILPHOTO').id,
								mimeType: item.title.split('.')[1],
							};
						}),
					};
					console.log(params, 'params');
					let promiseUrl = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					promiseUrl
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
								this.saveLoading = false;
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.ruleForm.images = [];
			this.$refs.uploadImage.images = [];
			this.$refs[formName].resetFields();
		},
		uploadImg(dataImg) {
			this.ruleForm.images = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.addressClass {
		&-content {
			width: calc((100% - 32px) / 3);
			margin-right: 16px;
			margin-bottom: 0;
		}
	}
	/deep/ .el-textarea__inner {
		min-height: 64px !important;
		line-height: 18px !important;
		padding: 5px 12px !important;
	}
}
.attractionAddress {
	/deep/ .el-button {
		width: 56px;
		border-bottom: 1px solid #1db9b1;
		margin-left: 16px;
		padding: 0;
		&:hover {
			color: #33a7a1;
			border-bottom: 1px solid #33a7a1;
		}
	}
}
/deep/ .el-button {
	margin-left: 16px;
}

/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .w40 {
	width: calc(50% - 14px);
	display: inline-block;
}
/deep/ .m28 {
	margin-right: 28px;
}
</style>